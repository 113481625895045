














































import { Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import FormComponent from '@/mixins/form-component';
import { WorkOrderConfigDetailEntityModel } from '@/entity-model/work-order-config-detail-entity';
import { ViewModeType, PropertyEntityType } from '@/model/enum';
import WorkOrderConfigDetailService from '@/service/work-order-config-detail';
import CommonService from '@/service/common';
import SpaceV2Service from '@/service/space-v2';
import { generateUUID } from '@/utils/base_util';
@Component
export default class WorkOrderDetailConditionComponent extends BaseComponent {
    @Prop()
    record;

    @Prop()
    viewType;

    @Prop()
    visible;

    @Prop()
    fields;

    @Watch('visible', { immediate: true, deep: true })
    visibleChanged() {
        if (this.visible) {
            this.init();
        }
    }

    /**
     * 表单组件
     */
    @Ref()
    basicForm: FormComponent<any>;

    workOrderConfigDetailEntityModel: WorkOrderConfigDetailEntityModel = new WorkOrderConfigDetailEntityModel();

    service: any;

    ViewModeType = ViewModeType;

    /* 条件 */
    fieldOptions = [];

    operatorOptionsConst = [
        {
            value: 'Equal',
            label: '等于',
            dataType: ['NUMBER', 'TEXT', 'RICHTEXT', 'DATE', 'DROPDOWN', 'MULTISELECT']
        },
        {
            value: 'NotEqual',
            label: '不等于',
            dataType: ['NUMBER', 'TEXT', 'RICHTEXT', 'DATE', 'DROPDOWN', 'MULTISELECT']
        },
        {
            value: 'Gt',
            label: '大于',
            dataType: ['NUMBER', 'DATE']
        },
        {
            value: 'Gte',
            label: '大于等于',
            dataType: ['NUMBER', 'DATE']
        },
        {
            value: 'Lt',
            label: '小于',
            dataType: ['NUMBER', 'DATE']
        },
        {
            value: 'Lte',
            label: '小于等于',
            dataType: ['NUMBER', 'DATE']
        }
    ];

    operatorOptions = [
        {
            value: 'Equal',
            label: '等于',
            dataType: ['NUMBER', 'TEXT', 'RICHTEXT', 'DATE', 'DROPDOWN', 'MULTISELECT']
        },
        {
            value: 'NotEqual',
            label: '不等于',
            dataType: ['NUMBER', 'TEXT', 'RICHTEXT', 'DATE', 'DROPDOWN', 'MULTISELECT']
        },
        {
            value: 'Gt',
            label: '大于',
            dataType: ['NUMBER', 'DATE']
        },
        {
            value: 'Gte',
            label: '大于等于',
            dataType: ['NUMBER', 'DATE']
        },
        {
            value: 'Lt',
            label: '小于',
            dataType: ['NUMBER', 'DATE']
        },
        {
            value: 'Lte',
            label: '小于等于',
            dataType: ['NUMBER', 'DATE']
        }
    ];

    connectorOptions = [
        {
            value: 'And',
            label: 'And'
        },
        {
            value: 'Or',
            label: 'Or'
        }
    ];

    fieldTypeMap = {
        'NUMBER': 'NUMBER',
        'TEXT': 'TEXT',
        'PERSON': 'TEXT',
        'MOBILEPHONE': 'TEXT',
        'RICHTEXT': 'RICHTEXT',
        'TABLE': 'RICHTEXT',
        'DATE': 'DATE',
        'DROPDOWN': 'DROPDOWN',
        'DEPARTMENT': 'DROPDOWN',
        'PROFESSION': 'DROPDOWN',
        'SPACE': 'DROPDOWN',
        'DEVICE': 'DROPDOWN',
        'SPAREASSET': 'DROPDOWN',
        'LEVEL': 'DROPDOWN',
        'MULTISELECT': 'MULTISELECT',
        'IMAGE': 'IMAGE',
        'IMAGES': 'IMAGES',
        'ATTACHMENT': 'ATTACHMENT',
        'ATTACHMENTS': 'ATTACHMENTS'
    };

    init() {
        this.service = WorkOrderConfigDetailService;

        this.workOrderConfigDetailEntityModel = new WorkOrderConfigDetailEntityModel();

        this.fieldOptions = _.map(this.fields, item => {
            return {
                value: item.name,
                label: item.displayName,
                dataType: item.dataType,
                options: item.options
            };
        });

        this.fieldOptions = _.filter(this.fieldOptions, item => {
            return this.fieldTypeMap[item.dataType] !== 'IMAGE' && this.fieldTypeMap[item.dataType] !== 'IMAGES' && this.fieldTypeMap[item.dataType] !== 'ATTACHMENT' && this.fieldTypeMap[item.dataType] !== 'ATTACHMENTS';
        });

        _.map(Object.keys(this.record), item => {
            this.workOrderConfigDetailEntityModel[item] = this.record[item];
        });

        if (this.viewType === ViewModeType.NEW) {
            this.workOrderConfigDetailEntityModel.fieldMatches = [{
                fieldId: '',
                operator: 'Equal',
                nval: '',
                sval: '',
                connectorType: 'And',
                id: generateUUID(),
                options: []
            }];
        } else {
            _.map(this.workOrderConfigDetailEntityModel.fieldMatches, item => {
                item.id = generateUUID();
                item.options = [];
                this.fieldChanged(item.fieldId, item, true);
            });
        }

        this.$nextTick(() => {
            this.basicForm.initForm(this.workOrderConfigDetailEntityModel, this.viewType);
        });
    }

    fieldChanged(value, condition, init:boolean = false) {
        let fd = _.filter(this.fieldOptions, item => {
            return item.value === value;
        })[0];

        condition.dataType = fd.dataType;

        if (fd.options && fd.options.length > 0) {
            condition.options = _.map(fd.options, item => {
                return {
                    value: item.value,
                    label: item.name
                };
            });
        } else {
            condition.options = [];
        }

        this.operatorOptions = _.filter(this.operatorOptionsConst, item => {
            return item.dataType.indexOf(this.fieldTypeMap[condition.dataType]) > -1;
        });

        if (!init) {
            condition.sval = '';

            condition.nval = '';
        }

        switch (condition.dataType) {
            case 'DEPARTMENT':
                CommonService.getOrgs().then(res => {
                    condition.options = res;
                    this.$forceUpdate();
                });
                break;
            case 'PROFESSION':
                CommonService.getProfessionalTree().then(res => {
                    condition.options = res;
                    this.$forceUpdate();
                });
                break;
            case 'SPACE':
                SpaceV2Service.getLocationTree().then(res => {
                    condition.options = res;
                    this.$forceUpdate();
                });
                break;
            case 'PERSON':
                CommonService.getPersonList().then(res => {
                    condition.options = _.map(res, item => {
                        return {
                            value: item.value,
                            label: item.name
                        };
                    });
                    this.$forceUpdate();
                });
                break;
            case 'LEVEL':
                CommonService.getPropertyOptions(PropertyEntityType.WORK_ORDER_LEVEL_CONF).then(res => {
                    condition.options = _.map(res, item => {
                        return {
                            value: item.value,
                            label: item.name
                        };
                    });
                    this.$forceUpdate();
                });
                break;
            case 'DEVICE':
                CommonService.getAssetsList().then(res => {
                    condition.options = res;
                    this.$forceUpdate();
                });
                break;
            default:
                break;
        }
    }

    addCondition() {
        this.workOrderConfigDetailEntityModel.fieldMatches.push({
            fieldId: '',
            dataType: 'TEXT',
            operator: 'Equal',
            nval: '',
            sval: '',
            connectorType: 'And',
            id: generateUUID(),
            options: []
        });
    }

    deleteCondition(condition) {
        this.workOrderConfigDetailEntityModel.fieldMatches = _.filter(this.workOrderConfigDetailEntityModel.fieldMatches, item => {
            return item.id !== condition.id;
        });
    }

    save() {
        this.basicForm.submitForm().then(res => {
            let flag = true;

            _.forEach(this.workOrderConfigDetailEntityModel.fieldMatches, item => {
                if (!item.fieldId || (!item.sval && !item.nval)) {
                    flag = false;
                }
            });

            if (!flag) {
                this.showMessageWarning('条件配置校验失败');
                return;
            }

            this.workOrderConfigDetailEntityModel.groupId = this.basicForm.formModel['groupId'];

            this.workOrderConfigDetailEntityModel.userId = this.basicForm.formModel['userId'];

            this.workOrderConfigDetailEntityModel.name = this.basicForm.formModel['name'];

            const model = this.workOrderConfigDetailEntityModel;

            _.map(model.fieldMatches, item => {
                delete item.options;
            });

            if (this.viewType === ViewModeType.NEW) {
                this.service.create(model).then(res => {
                    this.showMessageSuccess('新增成功');

                    this.$emit('closeCondition');
                });
            } else {
                this.service.update(model).then(res => {
                    this.showMessageSuccess('更新成功');

                    this.$emit('closeCondition');
                });
            }
        });
    }

    cancel() {
        this.$emit('closeCondition');
    }
}
