
import { get, post, put, del } from './request';
import { WorkOrderConfigDetailEntityModel, WorkOrderConfigDetailQueryModel } from '@/entity-model/work-order-config-detail-entity';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/actionFlowActiFormField`;

const CONDITION_PATH = `${WORKORDER_BASE_REQUEST_PATH}/actionFlowAssignment/condition`;

class WorkOrderConfigDetailService {
    async create(model: WorkOrderConfigDetailEntityModel):Promise<WorkOrderConfigDetailEntityModel> {
        const url = `${CONDITION_PATH}/${model.assignmentId}/conditionItem`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    /* 未使用 */
    async retrieve(modelId: string):Promise<WorkOrderConfigDetailEntityModel> {
        const url = `${CONDITION_PATH}/${modelId}`;
        const res = await get(url);
        return new WorkOrderConfigDetailEntityModel().toModel(res);
    }

    async update(model: WorkOrderConfigDetailEntityModel):Promise<WorkOrderConfigDetailEntityModel> {
        const url = `${CONDITION_PATH}/${model.assignmentId}/conditionItem`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: WorkOrderConfigDetailEntityModel):Promise<WorkOrderConfigDetailEntityModel> {
        const url = `${CONDITION_PATH}/${model.assignmentId}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderConfigDetailQueryModel, page?: number, limit?: number):Promise<any> {
        // const url = `${URL_PATH}/list`;
        const url = `${URL_PATH}/query`;

        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // const res = await post(url, {});

        res.items = _.map(res.items, item => item = new WorkOrderConfigDetailEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderConfigEntityModel().toModel(item));
    }

    async getFlowTreeData(id:string):Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/workorder-type/process-definition-relation/${id}/tasksandflow`;
        const res = await get(url);
        _.forEach(res, item => {
            item.task = true;
        });
        return res;
    }

    async getFields(id:string, actionId:string):Promise<any> {
        const url = `${URL_PATH}/${id}/${actionId}/fields`;
        const res = await get(url);
        return res;
    }

    async saveFields(id:string, actionId:string, fields:any):Promise<any> {
        const url = `${URL_PATH}/${id}/${actionId}/fields`;
        const res = await put(url, fields);
        return res;
    }

    async getCandidate(id:string, actionId:string):Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/actionFlowAssignment/getAssignments`;
        const params = { actionFlowKey: actionId, relationId: id };
        const res = await post(url, params);
        let data = { id: '', type: 'role', groupId: '', userId: '', roleId: 'creator', professionId: '', actionId: '', conditions: [] };

        if (res && res.assignmentType) {
            data.id = res.id;
            data.type = res.assignmentType;

            switch (res.assignmentType) {
                case 'User':
                    data.userId = JSON.parse(res.options)['userId'];
                    data.groupId = JSON.parse(res.options)['groupId'];
                    break;
                case 'Role':
                    data.roleId = JSON.parse(res.options)['roleId'];
                    data.userId = JSON.parse(res.options)['userId'];
                    data.groupId = JSON.parse(res.options)['groupId'];
                    break;
                case 'Group':
                    data.groupId = JSON.parse(res.options)['groupId'];
                    break;
                case 'Profession':
                    data.userId = JSON.parse(res.options)['userId'];
                    data.groupId = JSON.parse(res.options)['groupId'];
                    break;
                case 'OtherCondition':
                    data.actionId = JSON.parse(res.options)['actionId'];
                    break;
                case 'Condition':
                    data.groupId = JSON.parse(res.options)['groupId'];
                    data.userId = JSON.parse(res.options)['userId'];
                    data.conditions = res.conditions;
                    break;
            }
        }

        return data;
    }

    async saveCanditate(canditate:any):Promise<any> {
        let params = { id: canditate.id, assignmentType: canditate.type, options: '' };

        switch (params.assignmentType) {
            case 'User':
                params.options = JSON.stringify({ userId: canditate.userId, groupId: canditate.groupId });
                break;
            case 'Role':
                params.options = JSON.stringify({ roleId: canditate.roleId, userId: canditate.userId, groupId: canditate.groupId });
                break;
            case 'Group':
                params.options = JSON.stringify({ groupId: canditate.groupId });
                break;
            case 'Profession':
                params.options = JSON.stringify({ userId: canditate.userId, groupId: canditate.groupId });
                break;
            case 'OtherCondition':
                params.options = JSON.stringify({ actionId: canditate.actionId });
                break;
            case 'Condition':
                params.options = JSON.stringify({ userId: canditate.userId, groupId: canditate.groupId });
                break;
        }

        const url = `${WORKORDER_BASE_REQUEST_PATH}/actionFlowAssignment/updateAssignment`;
        const res = await post(url, params);
        return res;
    }

    async getAddition(id:string, actionId:string):Promise<any> {
        const url = `${URL_PATH}/getAttachments`;
        const params = { actionFlowKey: actionId, relationId: id };
        const res = await post(url, params);
        let data = { commentId: '', commentName: '意见', commentRequired: false, commentVisible: true, pictureId: '', pictureName: '意见', pictureRequired: false, pictureVisible: false };
        _.map(res, item => {
            if (item.key === 'comment') {
                data.commentId = item.id;
                data.commentName = item.name;
                data.commentRequired = item.required;
                data.commentVisible = item.visible;
            } else if (item.key === 'commentImages') {
                data.pictureId = item.id;
                data.pictureName = item.name;
                data.pictureRequired = item.required;
                data.pictureVisible = item.visible;
            }
        });
        return data;
    }

    async saveAddition(addition:any):Promise<any> {
        const url = `${URL_PATH}/updateAttachments`;
        const params = [];
        params.push({
            id: addition.commentId,
            name: addition.commentName,
            required: addition.commentRequired,
            visible: addition.commentVisible
        });
        params.push({
            id: addition.pictureId,
            name: addition.pictureName,
            required: addition.pictureRequired,
            visible: addition.pictureVisible
        });
        const res = await post(url, params);
        return res;
    }
}
export default new WorkOrderConfigDetailService();
